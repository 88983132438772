import { Spinner } from '@chakra-ui/react'
import { zodResolver } from '@hookform/resolvers/zod'
import { LoadingButton } from '@mui/lab'
import { Stack, TextField, Typography } from '@mui/material'
import { AxiosError } from 'axios'
import { FC, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router'
import { useSearchParams } from 'react-router-dom'

import { Schemas } from '~/apis/types'
import { CInputLabel } from '~/components/common/cInputLabel/CInputLabel'
import { DefaultLayout } from '~/components/layout/Default'
import { useConfirmationDialog } from '~/hooks/useConfirmationDialog'
import { useAuthState } from '~/store/auth'
import { useOrderState } from '~/store/order'
import { ClientUserSetNicknameDtoSchema, clientUserSetNicknameDtoSchema } from '~/types/zodScheme'
import { createApiClient } from '~/utils/createApiClient'

export const useLoginStatusPage = () => {
    const apiClient = createApiClient()
    const { queueDialog } = useConfirmationDialog()
    const params = useParams()
    const { setToken, setMe, me } = useAuthState()
    const [searchParams] = useSearchParams()
    const accessToken = searchParams.get('token')
    const expire = searchParams.get('expire')
    const isSignup = searchParams.get('isSignup') === 'true'
    const { setOrderToken } = useOrderState()
    const navigate = useNavigate()

    const loginHandler = async () => {
        if (params.status !== 'success' || !accessToken || !expire) throw new Error('ログインに失敗しました')
        const res: Schemas.ClientLoginResultDto = {
            accessToken,
            expire,
        }

        setToken(res)
        const me = await apiClient.clientAuthUserMe()
        setMe(me)
        await apiClient.clientGiftOrderRequestInit({})
        setOrderToken(me.uuid)
    }

    useEffect(() => {
        const onPageLoad = async () => {
            try {
                await loginHandler()
                if (!isSignup) navigate('/user')
            } catch (e) {
                let message = 'ログインに失敗しました'
                if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
                await queueDialog({
                    type: 'alert',
                    title: 'エラーが発生しました',
                    text: message,
                })
                navigate('/login')
            }
        }
        onPageLoad()
    }, [])

    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitting, isValid },
        reset,
    } = useForm<ClientUserSetNicknameDtoSchema>({
        mode: 'onBlur',
        resolver: zodResolver(clientUserSetNicknameDtoSchema),
    })

    useEffect(() => {
        reset({
            nickname: me?.name ?? '',
        })
    }, [isSubmitting, me])

    const sendHandler = async (dto: Schemas.ClientUserNicknameUpdateDto) => {
        try {
            await apiClient.clientUserSetNickname({ requestBody: dto })
            await loginHandler()
            navigate(`/user`, { replace: true })
        } catch (e) {
            let message = '登録に失敗しました'
            if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
            await queueDialog({
                type: 'alert',
                title: 'エラーが発生しました',
                text: message,
            })
        }
    }

    return { isSignup, register, handleSubmit, errors, isSubmitting, isValid, sendHandler }
}

export const LoginStatusPage: FC = () => {
    const { isSignup, register, handleSubmit, errors, isSubmitting, isValid, sendHandler } = useLoginStatusPage()
    return (
        <>
            <DefaultLayout title={isSignup ? '新規会員登録' : 'ログイン中です。。'} breadcrumbList={[]}>
                {!isSignup && <Spinner />}
                {isSignup && (
                    <Stack spacing={3}>
                        <Typography variant="body2">ニックネームはマイページからいつでも編集することができます。</Typography>
                        <Stack spacing={0.5}>
                            <CInputLabel label="ニックネーム" required />
                            <Typography variant="caption">これ終で表示される名前・ニックネーム</Typography>
                            <TextField
                                {...register('nickname')}
                                placeholder={'ニックネーム'}
                                type={'text'}
                                error={!!errors.nickname}
                                helperText={errors.nickname?.message}
                                variant={'outlined'}
                            />
                        </Stack>

                        <LoadingButton
                            variant={'contained'}
                            disabled={!isValid}
                            loading={isSubmitting}
                            onClick={handleSubmit(sendHandler)}>
                            この内容で登録
                        </LoadingButton>
                    </Stack>
                )}
            </DefaultLayout>
        </>
    )
}
