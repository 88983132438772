import { zodResolver } from '@hookform/resolvers/zod'
import RefreshIcon from '@mui/icons-material/Refresh'
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle'
import { LoadingButton } from '@mui/lab'
import {
    Box,
    Button,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemText,
    Pagination,
    Stack,
    TextField,
    Typography,
} from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { ChangeEvent, FC, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

import { Schemas } from '~/apis/types'
import { CInputLabel } from '~/components/common/cInputLabel/CInputLabel'
import { useConfirmationDialog } from '~/hooks/useConfirmationDialog'
import { ClientGraveCommentCreateDtoSchema, clientGraveCommentCreateDtoSchema } from '~/types/zodScheme'
import { datetimeFormat } from '~/utils/common'
import { createApiClient } from '~/utils/createApiClient'

const useCConnectGraveCommentList = (connectUuid?: string) => {
    const apiClient = createApiClient()
    const { queueDialog } = useConfirmationDialog()

    const [queryParams, setQueryParams] = useState({ page: 1, limit: 10 })

    const { data: commentListResponse, refetch } = useQuery(
        [`/connectList/grave/Comment`],
        async () => {
            return await apiClient.clientConnectGraveGetList({
                parameter: {
                    connectUuid: connectUuid ?? '',
                    ...queryParams,
                },
            })
        },
        {
            onError: async (e) => {
                let message = 'コメント取得に失敗しました'
                if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
                await queueDialog({
                    type: 'alert',
                    title: 'エラーが発生しました',
                    text: message,
                })
            },
            enabled: !!connectUuid,
            suspense: true,
        },
    )

    // コメント制御
    const handlePageChange = async (page: number) => {
        queryParams.page = page
        setQueryParams(queryParams)
        await refetch()
    }
    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitting },
        reset,
    } = useForm<ClientGraveCommentCreateDtoSchema>({
        mode: 'onBlur',
        resolver: zodResolver(clientGraveCommentCreateDtoSchema),
    })

    useEffect(() => {
        reset({
            comment: '',
        })
    }, [isSubmitting])

    const sendHandler = async (dto: Schemas.ClientGraveCommentCreateDto) => {
        try {
            await apiClient.clientConnectGraveCreate({
                parameter: { connectUuid: connectUuid ?? '' },
                requestBody: dto,
            })
            await refetch()
        } catch (e) {
            await queueDialog({
                type: 'alert',
                title: 'エラーが発生しました',
                text: '送信に失敗しました',
            })
        }
    }

    const deleteHandler = async (comment: Schemas.GraveCommentEntities) => {
        try {
            const message = '削除します。よろしいでしょうか？'
            if (!comment) return
            if (
                await queueDialog({
                    type: 'confirm',
                    title: '削除',
                    text: message,
                })
            ) {
                await apiClient.clientConnectGraveDelete({
                    parameter: { connectUuid: connectUuid ?? '', commentUuid: comment.uuid },
                })
                await refetch()
            }
        } catch (e) {
            let message = '削除に失敗しました'
            if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
            await queueDialog({
                type: 'alert',
                title: 'エラーが発生しました',
                text: message,
            })
        }
    }

    // 投稿は hooks の isValid ではなく onChange したヤツを使用する。（focusを変更しないとCheckが走らないため)
    const [isValid, setIsValid] = useState<boolean>(false)
    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.value) setIsValid(true)
        else setIsValid(false)
    }

    const reduceOrderDetails = (details: Schemas.OfferingOrderDetailEntities[]) => {
        type resultType = {
            name: string
            uuid: string
            count: number
        }
        return details.reduce((result: resultType[], current: Schemas.OfferingOrderDetailEntities) => {
            const element = result.find((p) => p.uuid === current.offeringMaster.uuid)
            if (element) element.count++
            else {
                result.push({
                    name: current.offeringMaster.name,
                    uuid: current.offeringMaster.uuid,
                    count: 1,
                })
            }
            return result
        }, [])
    }

    return {
        queryParams,
        list: commentListResponse?.list || [],
        count: commentListResponse?.count || 0,
        handlePageChange,

        register,
        handleSubmit,
        errors,
        isSubmitting,
        sendHandler,
        deleteHandler,
        handleChange,
        isValid,
        refetch,
        reduceOrderDetails,
    }
}

type CConnectGraveCommentListProps = {
    connectUuid?: string
}

export const CConnectGraveCommentList: FC<CConnectGraveCommentListProps> = ({ connectUuid }) => {
    const {
        queryParams,
        list,
        count,
        handlePageChange,

        register,
        handleSubmit,
        errors,
        isSubmitting,

        sendHandler,
        deleteHandler,
        handleChange,
        isValid,
        refetch,
        reduceOrderDetails,
    } = useCConnectGraveCommentList(connectUuid)
    return (
        <>
            {list.length > 0 && (
                <List>
                    {list.map((comment: Schemas.GraveCommentEntities, listIndex) => (
                        <Box key={comment.uuid + listIndex.toString()}>
                            {comment.offeringOrder && (
                                <Box sx={{ pl: 2, pt: 2 }}>
                                    <Typography>お供えリスト</Typography>
                                    {reduceOrderDetails(comment.offeringOrder.details).map((detail, detailIndex) => (
                                        <Typography sx={{ pl: 2 }} key={comment.uuid + detail.uuid + detailIndex.toString()}>
                                            ・{detail.name} {detail.count} 個
                                        </Typography>
                                    ))}
                                </Box>
                            )}
                            <ListItem divider>
                                <ListItemText
                                    primary={
                                        <Stack>
                                            <Typography variant="body2" sx={{ whiteSpace: 'pre-wrap' }}>
                                                {comment.comment}
                                            </Typography>
                                            <Typography variant="body1">
                                                {comment.connect ? (
                                                    <>
                                                        {comment.connect.name} {datetimeFormat(comment.createdAt)}
                                                    </>
                                                ) : (
                                                    <>{datetimeFormat(comment.createdAt)}</>
                                                )}
                                            </Typography>
                                        </Stack>
                                    }
                                    secondary={
                                        comment.isSelf ? (
                                            <>
                                                <Stack direction={'row'} justifyContent={'end'}>
                                                    <Button
                                                        variant="text"
                                                        color="error"
                                                        onClick={() => deleteHandler(comment)}
                                                        startIcon={<RemoveCircleIcon />}>
                                                        削除
                                                    </Button>
                                                </Stack>
                                            </>
                                        ) : (
                                            <></>
                                        )
                                    }
                                />
                            </ListItem>
                        </Box>
                    ))}
                </List>
            )}
            {count >= 1 ? (
                <Grid container direction="row" justifyContent="center" alignItems="center">
                    <Pagination
                        count={Math.floor(count / queryParams.limit) + (count % queryParams.limit === 0 ? 0 : 1)}
                        page={queryParams.page}
                        onChange={(_, value) => handlePageChange(value)}
                    />
                </Grid>
            ) : (
                <Typography variant="body1">まだコメントがありません</Typography>
            )}
            <Stack spacing={2}>
                <Box sx={{ display: 'flex', justifyContent: 'end' }}>
                    <IconButton color="primary" onClick={() => refetch()} size={'small'}>
                        <RefreshIcon />
                    </IconButton>
                </Box>
                <Stack spacing={0.5}>
                    <CInputLabel label={'コメント'} required />
                    <TextField
                        id={'comment'}
                        {...register!('comment')}
                        placeholder={'コメント'}
                        multiline
                        rows={3}
                        error={!!errors?.comment}
                        helperText={errors?.comment?.message}
                        variant={'outlined'}
                        onChange={handleChange}
                    />
                </Stack>
                <LoadingButton
                    variant={'contained'}
                    onClick={handleSubmit(sendHandler)}
                    disabled={!isValid}
                    loading={isSubmitting}>
                    投稿
                </LoadingButton>
            </Stack>
        </>
    )
}
