import { Box, Stack, Typography } from '@mui/material'
import { type FC, ReactNode, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import triangleIcon from '~/assets/image/help/mypage/arrowIcon.svg'
import { CConnectHelp } from '~/components/functional/help/cConnectHelp/CConnectHelp'
import { CHelp } from '~/components/functional/help/cHelp/CHelp'
import { CMemoryHelp } from '~/components/functional/help/cMemoryHelp/CMemoryHelp'
import { CUserHelp } from '~/components/functional/help/cUserHelp/CUserHelp'
import { DefaultLayout } from '~/components/layout/Default'
import { useHelpSourceState } from '~/store/help-section-name'

export type HelpItems = {
    heading?: ReactNode
    content?: ReactNode
    image?: string
    imageTwo?: string
}
type LinkItems = {
    linkName: string
    onLinkClick: () => void
}

export const useHelpPage = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const { sectionName, setSectionName, clearSectionName } = useHelpSourceState()
    const searchParams = new URLSearchParams(location.search)
    const path = searchParams.get('source') || 'user'

    const [showTopButton, setShowTopButton] = useState(false)
    useEffect(() => {
        const handleButtonScroll = () => {
            const scrollPosition = window.scrollY
            setShowTopButton(scrollPosition > 500)
        }
        window.addEventListener('scroll', handleButtonScroll)
        return () => {
            window.removeEventListener('scroll', handleButtonScroll)
        }
    }, [])

    const handleTopButtonClick = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        })
    }

    //このアプローチが使用されたのは、リンクが 3 つの異なるページの異なるセクション間を移動するためです。
    const handleLinkClick = (sectionName: string, path: string, newTab = false) => {
        setSectionName(sectionName)
        if (!newTab) window.location.href = `/help?source=${path}`
        window.open(`/help?source=${path}`, '_blank', 'noopener,noreferrer')

        //ローカル ストレージのアプローチを回避する必要がある場合は、この行を追加する必要があります。
        // await new Promise((res) => setTimeout(res, 1))
    }

    useEffect(() => {
        if (path && ['user', 'connectList', 'memory'].includes(path) && sectionName) {
            const elementId = document.getElementById(sectionName)
            if (elementId) {
                /* navbarHeight は、 <GHeader /> コンポーネントでチェックされたように 50 ピクセルであったため、
                    80 ピクセルとして取得されますが、見栄えを良くするために追加の 30 ピクセルが与えられています。*/
                const navbarHeight = 80
                const offset = elementId.offsetTop - navbarHeight
                window.scrollTo({
                    top: offset,
                    behavior: 'smooth',
                })
                clearSectionName()
            }
        }
    }, [])

    const myPageLinkItems: LinkItems[] = [
        {
            linkName: 'お知らせ',
            onLinkClick: () => handleLinkClick('notice', 'user'),
        },
        {
            linkName: '招待URL',
            onLinkClick: () => handleLinkClick('inviteURL', 'user'),
        },
        {
            linkName: 'プロフィール',
            onLinkClick: () => handleLinkClick('profile', 'user'),
        },
        {
            linkName: '自分史',
            onLinkClick: () => handleLinkClick('personalHistory', 'user'),
        },
        {
            linkName: 'マイお墓',
            onLinkClick: () => handleLinkClick('grave', 'user'),
        },
        {
            linkName: 'お墓を管理する',
            onLinkClick: () => handleLinkClick('graveMaintain', 'user'),
        },
        {
            linkName: '身内・友人のお墓をつくる',
            onLinkClick: () => handleLinkClick('relativesGrave', 'user'),
        },
    ]

    const connectLinkItems: LinkItems[] = [
        {
            linkName: 'つながるとできること',
            onLinkClick: () => handleLinkClick('connectRules', 'connectList'),
        },
        {
            linkName: 'つながり登録・申請方法',
            onLinkClick: () => handleLinkClick('registerConnections', 'connectList'),
        },
        {
            linkName: 'つながり申請の確認方法',
            onLinkClick: () => handleLinkClick('confirmConnections', 'connectList'),
        },
        {
            linkName: 'タグについて',
            onLinkClick: () => handleLinkClick('tags', 'connectList'),
        },
        {
            linkName: '思い出共有について',
            onLinkClick: () => handleLinkClick('sharingMemories', 'connectList'),
        },
        {
            linkName: 'お墓参りについて',
            onLinkClick: () => handleLinkClick('graveVisit', 'connectList'),
        },
        // {
        //     linkName: 'ギフトの送り方',
        //     onLinkClick: () => handleLinkClick('gift', 'connectList'),
        // },
    ]

    const memoryLinkItems: LinkItems[] = [
        {
            linkName: 'アルバム作成',
            onLinkClick: () => handleLinkClick('albumCreation', 'memory'),
        },
        {
            linkName: 'アルバムの共有',
            onLinkClick: () => handleLinkClick('shareAlbums', 'memory'),
        },
        {
            linkName: 'コメント機能',
            onLinkClick: () => handleLinkClick('comments', 'memory'),
        },
        {
            linkName: '共有された思い出',
            onLinkClick: () => handleLinkClick('sharedMemories', 'memory'),
        },
    ]

    return {
        myPageLinkItems,
        path,
        navigate,
        handleTopButtonClick,
        memoryLinkItems,
        connectLinkItems,
        handleLinkClick,
        showTopButton,
    }
}

export const HelpPage: FC = () => {
    const {
        myPageLinkItems,
        path,
        navigate,
        handleTopButtonClick,
        memoryLinkItems,
        connectLinkItems,
        handleLinkClick,
        showTopButton,
    } = useHelpPage()
    return (
        <DefaultLayout title={''} breadcrumbList={[]} isTab={false}>
            {path === 'user' && (
                <>
                    <Stack mb={'7.813rem'}>
                        <CHelp
                            title={'マイページ'}
                            content={
                                '「マイページ」画面では、あなたのプロフィール情報の編集や、お知らせの確認、マイお墓の設定ができます。'
                            }
                            navLinkItems={myPageLinkItems}
                            linkNavigate={() => navigate('/user')}
                        />
                        <CUserHelp onLinkClick={handleLinkClick} />
                    </Stack>
                </>
            )}

            {path === 'connectList' && (
                <Stack mb={'7.813rem'}>
                    <CHelp
                        title={'つながり機能'}
                        content={'「つながり」画面では、お世話になった人の情報を登録して交友関係を整理できます。'}
                        navLinkItems={connectLinkItems}
                        linkNavigate={() => navigate('/connectList')}
                    />
                    <CConnectHelp onLinkClick={handleLinkClick} />
                </Stack>
            )}

            {path === 'memory' && (
                <Stack mb={'7.813rem'}>
                    <CHelp
                        title={'思い出'}
                        content={
                            '「思い出」画面では、日々の写真やイベントなどの記憶をアルバムにまとめることができます。つながっているユーザーとアルバムを共有することもできますし、コメント機能でメモを残したりメッセージ性のあるやり取りも行えます。'
                        }
                        navLinkItems={memoryLinkItems}
                        linkNavigate={() => navigate('/memory/self')}
                    />
                    <CMemoryHelp onLinkClick={handleLinkClick} />
                </Stack>
            )}

            {showTopButton && (
                <Box
                    onClick={handleTopButtonClick}
                    height={'44px'}
                    width={'200px'}
                    padding={'1rem'}
                    border={'none'}
                    borderRadius={'30px'}
                    position="fixed"
                    bottom={'56px'}
                    left="50%"
                    boxShadow={'0rem 0rem 0.625rem 0rem rgba(0, 0, 0, 0.25)'}
                    sx={{ cursor: 'pointer', backgroundColor: '#FFFFFF', transform: 'translateX(-50%)' }}
                    display={'flex'}
                    flexDirection={'row'}
                    justifyContent={'center'}
                    alignItems={'center'}>
                    <Stack display={'flex'} flexDirection={'row'} justifyContent={'center'} alignItems={'center'}>
                        <img src={triangleIcon} width={'12px'} height={'10px'} />
                        <Typography color={'#000000'} variant={'body1'} paddingLeft={1}>
                            ページトップへ戻る
                        </Typography>
                    </Stack>
                </Box>
            )}
        </DefaultLayout>
    )
}

export default HelpPage
