import { zodResolver } from '@hookform/resolvers/zod/dist/zod'
import { LoadingButton } from '@mui/lab'
import { Button, Container, Grid, Link, Stack, TextField, Typography } from '@mui/material'
import { AxiosError } from 'axios'
import { FC, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useLocation, useNavigate } from 'react-router-dom'

import { Schemas } from '~/apis/types'
import decorated from '~/assets/image/decorated.svg'
import lineHover from '~/assets/image/top/line_hover.png'
import lineLogin from '~/assets/image/top/line_login.png'
import linePress from '~/assets/image/top/line_press.png'
import { CInputLabel } from '~/components/common/cInputLabel/CInputLabel'
import { DefaultLayout } from '~/components/layout/Default'
import { useConfirmationDialog } from '~/hooks/useConfirmationDialog'
import { useAuthState } from '~/store/auth'
import { useOrderState } from '~/store/order'
import { UserLoginUserMobilePhoneDtoSchema, userLoginUserMobilePhoneDtoSchema } from '~/types/zodScheme'
import { createApiClient } from '~/utils/createApiClient'
// import { useLogin } from '~/components/functional/cLogin/useLogin'

export const useLoginIndexPage = () => {
    const apiClient = createApiClient()
    const navigate = useNavigate()
    const { queueDialog } = useConfirmationDialog()
    const { setToken, setMe } = useAuthState()
    const { setOrderToken } = useOrderState()
    const location = useLocation()
    console.log(location)

    const [isSendCreatePassword, setIsSendCreatePassword] = useState(false)

    /**
     * 携帯電話でログイン
     */
    const {
        register: createPasswordRegister,
        handleSubmit: handleSubmitCreatePassword,
        formState: { errors: errorsCreatePassword, isSubmitting: isSubmittingCreatePassword, isValid: isValidCreatePassword },
        setValue: setValueCreatePassword,
        watch,
    } = useForm<UserLoginUserMobilePhoneDtoSchema>({
        mode: 'onBlur',
        resolver: zodResolver(userLoginUserMobilePhoneDtoSchema),
    })

    const mobilePhoneValue = watch('mobilePhone')
    const emailValue = watch('email')

    useEffect(() => {
        setValueCreatePassword('password', '')
        setValueCreatePassword('email', '')
    }, [mobilePhoneValue])

    useEffect(() => {
        setValueCreatePassword('mobilePhone', '')
    }, [emailValue])

    const [isMobilePhoneLogin, setIsMobilePhoneLogin] = useState(false)

    const sendHandler = async (dto: Schemas.ClientUserMobilePhonePasswordRequestDto) => {
        try {
            await apiClient.clientUserCreatePassword({ requestBody: dto })
            setIsSendCreatePassword(true)
            setIsMobilePhoneLogin(true)
        } catch (e) {
            let message = '送信に失敗しました'
            if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
            await queueDialog({
                type: 'alert',
                title: 'エラーが発生しました',
                text: message,
            })
        }
    }

    const sendHandlerEmail = async (dto: Schemas.ClientUserEmailPasswordRequestDto) => {
        try {
            await apiClient.clientUserCreateEmailPassword({ requestBody: dto })
            setIsSendCreatePassword(true)
            setIsMobilePhoneLogin(false)
        } catch (e) {
            let message = '送信に失敗しました'
            if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
            await queueDialog({
                type: 'alert',
                title: 'エラーが発生しました',
                text: message,
            })
        }
    }

    const [isError, setIsError] = useState<boolean>(false)

    const loginHandler = async (dto: Schemas.ClientLoginUserDto) => {
        try {
            const requestDto: Schemas.ClientLoginUserDto = {
                email: dto.mobilePhone ? dto.mobilePhone : dto.email,
                password: dto.password,
            }
            const res = await apiClient.clientAuthLogin({ requestBody: requestDto })
            setToken(res)
            const me = await apiClient.clientAuthUserMe()
            setMe(me)
            await apiClient.clientGiftOrderRequestInit({})
            setOrderToken(me.uuid)
            navigate('/user', { replace: true })
        } catch (e) {
            setIsError(true)
            let message = 'ログインに失敗しました'
            if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
            await queueDialog({
                type: 'alert',
                title: 'エラーが発生しました',
                text: message,
            })
        }
    }

    const [lineIcon, setLineIcon] = useState<string>(lineLogin)
    const signInLine = async () => {
        const url = await apiClient.clientLineAuthLoginLine()
        window.location.href = url
    }

    return {
        isSendCreatePassword,
        setIsSendCreatePassword,
        createPasswordRegister,
        handleSubmitCreatePassword,
        errorsCreatePassword,
        isSubmittingCreatePassword,
        isValidCreatePassword,
        sendHandler,
        loginHandler,
        sendHandlerEmail,
        mobilePhoneValue,
        emailValue,
        isError,
        signInLine,
        lineIcon,
        setLineIcon,
        isMobilePhoneLogin,
    }
}

export const LoginIndexPage: FC = () => {
    const {
        isSendCreatePassword,
        setIsSendCreatePassword,

        createPasswordRegister,
        handleSubmitCreatePassword,
        errorsCreatePassword,
        isSubmittingCreatePassword,
        isValidCreatePassword,
        sendHandler,
        loginHandler,
        sendHandlerEmail,
        mobilePhoneValue,
        emailValue,
        isError,
        signInLine,
        lineIcon,
        setLineIcon,
        isMobilePhoneLogin,
    } = useLoginIndexPage()
    return (
        <>
            <DefaultLayout
                title={isSendCreatePassword ? 'ワンタイムパスワード入力' : 'ログイン'}
                breadcrumbList={[]}
                isTab={false}>
                <Container maxWidth={'sm'} sx={{ fontSize: '1.125rem', px: 1 }}>
                    <Stack spacing={4}>
                        {isSendCreatePassword ? (
                            <>
                                <Typography variant="body2" textAlign={'center'}>
                                    送信されたメッセージに記載されているワンタイムパスワードを入力してください。ワンタイムパスワードの有効期限は5分です。
                                </Typography>
                            </>
                        ) : (
                            <>
                                <Typography mt={2} variant="body2">
                                    これ終の
                                    <Link color={'#77618B'} fontWeight={'bold'} href="/terms" target="_blank">
                                        サービス利用規約
                                    </Link>
                                    と
                                    <Link color={'#77618B'} fontWeight={'bold'} href="/privacy" target="_blank">
                                        プライバシーポリシー
                                    </Link>
                                    に同意した上でログインをお願いします。
                                </Typography>
                            </>
                        )}

                        {isSendCreatePassword ? (
                            <Container>
                                <Stack spacing={0.5}>
                                    <CInputLabel label="ワンタイムパスワード" />
                                    <TextField
                                        {...createPasswordRegister('password')}
                                        placeholder={'000000'}
                                        type={'password'}
                                        error={!!errorsCreatePassword.password}
                                        helperText={errorsCreatePassword.password?.message}
                                        variant={'outlined'}
                                    />
                                    {isError && (
                                        <Typography variant="caption" color={'error'} fontSize="medium">
                                            入力したパスワードが間違っています。携帯番号に届いたワンタイムパスワードを確認して再度入力してください。
                                        </Typography>
                                    )}
                                </Stack>
                                <Stack spacing={2} mt={2}>
                                    <LoadingButton
                                        variant={'contained'}
                                        disabled={!isValidCreatePassword}
                                        loading={isSubmittingCreatePassword}
                                        sx={{ height: '3rem', width: '100%' }}
                                        onClick={handleSubmitCreatePassword(loginHandler)}>
                                        送信
                                    </LoadingButton>
                                    <Link
                                        component="button"
                                        variant="h6"
                                        disabled={!isValidCreatePassword}
                                        onClick={handleSubmitCreatePassword(isMobilePhoneLogin ? sendHandler : sendHandlerEmail)}>
                                        ワンタイムパスワードを再送信
                                    </Link>
                                </Stack>
                            </Container>
                        ) : (
                            <>
                                <Grid container justifyContent={'center'}>
                                    <Grid item xs={12} sm={6} py={1}>
                                        <Button
                                            variant={'outlined'}
                                            fullWidth
                                            startIcon={<img src={lineIcon} height={'24px'} />}
                                            onClick={() => signInLine()}
                                            onMouseEnter={() => setLineIcon(lineHover)}
                                            onMouseLeave={() => setLineIcon(lineLogin)}
                                            onMouseDown={() => setLineIcon(linePress)}
                                            onMouseUp={() => setLineIcon(lineHover)}>
                                            <Typography variant="body2" fontWeight={'bold'} fontFamily={'Noto Sans JP'}>
                                                LINE でログイン
                                            </Typography>
                                        </Button>
                                    </Grid>
                                </Grid>
                                <Container sx={{ fontSize: 'large', px: 0 }}>
                                    <Stack spacing={2}>
                                        <img src={decorated} style={{ marginBottom: '1.25rem' }} />
                                        <Stack spacing={1}>
                                            <CInputLabel label="携帯電話番号でログイン" />
                                            <Typography variant="caption">ハイフン(-)は除いて入力してください</Typography>
                                            <TextField
                                                {...createPasswordRegister('mobilePhone')}
                                                placeholder={'08000000000'}
                                                type={'text'}
                                                error={!!errorsCreatePassword.mobilePhone}
                                                helperText={errorsCreatePassword.mobilePhone?.message}
                                                variant={'outlined'}
                                            />
                                            <Typography variant="caption" fontSize="medium">
                                                入力していただいた携帯電話番号に6桁のワンタイムパスワードが送信されます。
                                            </Typography>
                                        </Stack>
                                        <LoadingButton
                                            variant={'contained'}
                                            disabled={!isValidCreatePassword || !mobilePhoneValue}
                                            loading={isSubmittingCreatePassword}
                                            sx={{ height: '3rem' }}
                                            onClick={handleSubmitCreatePassword(sendHandler)}>
                                            送信
                                        </LoadingButton>
                                    </Stack>

                                    <Stack mt={3} spacing={4}>
                                        <img src={decorated} width={'100%'} />
                                        <Stack spacing={1}>
                                            <CInputLabel label="メールアドレスでログイン" />
                                            <TextField
                                                {...createPasswordRegister('email')}
                                                placeholder={'login@koreshu.jp'}
                                                type={'text'}
                                                error={!!errorsCreatePassword.email}
                                                helperText={errorsCreatePassword.email?.message}
                                                variant={'outlined'}
                                            />
                                            <Typography variant="caption" fontSize="medium">
                                                入力していただいた携帯電話番号に6桁のワンタイムパスワードが送信されます。
                                            </Typography>
                                        </Stack>
                                        <LoadingButton
                                            variant={'contained'}
                                            disabled={!isValidCreatePassword || !emailValue}
                                            loading={isSubmittingCreatePassword}
                                            sx={{ height: '3rem' }}
                                            onClick={handleSubmitCreatePassword(sendHandlerEmail)}>
                                            送信
                                        </LoadingButton>
                                    </Stack>
                                </Container>
                            </>
                        )}
                    </Stack>
                </Container>
                <Container maxWidth={'sm'} sx={{ my: 4, fontSize: 'large', px: 0 }}>
                    <Stack spacing={4}>
                        <img src={decorated} width={'100%'} />
                        <Stack sx={{ alignItems: 'center' }}>
                            <Typography variant="button" fontSize={'large'}>
                                会員登録していない場合は
                            </Typography>
                            {isSendCreatePassword ? (
                                <Button
                                    variant={'text'}
                                    sx={{ textDecoration: 'underline', p: 0, mt: 1 }}
                                    fullWidth
                                    onClick={() => setIsSendCreatePassword(false)}>
                                    新規会員登録
                                </Button>
                            ) : (
                                <Link color={'#77618B'} href="/user/account" mt={1}>
                                    新規会員登録
                                </Link>
                            )}
                        </Stack>
                        <Stack spacing={4} sx={{ alignItems: 'center' }}>
                            <img src={decorated} width={'100%'} />
                            <Link color={'#77618B'} href="/inquiry">
                                お問合せ
                            </Link>
                        </Stack>
                    </Stack>
                </Container>
            </DefaultLayout>
        </>
    )
}
