import { useDisclosure } from '@chakra-ui/hooks'
import ErrorIcon from '@mui/icons-material/Error'
import { Avatar, Box, Button, Container, Divider, IconButton, Link, Stack, Typography } from '@mui/material'
import { AxiosError } from 'axios'
import { FC, useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { Link as RouterLink } from 'react-router-dom'

import { Schemas } from '~/apis/types'
import helpIcon from '~/assets/image/connect/helpIcon.svg'
import iconEdit from '~/assets/image/icon/edit.svg'
import connectLink from '~/assets/image/icon/link.svg'
import personalHistory from '~/assets/image/icon/personal-history.svg'
import iconTomb from '~/assets/image/icon/tomb.svg'
import informationIcon from '~/assets/image/informationIcon.svg'
import { CProfileBox } from '~/components/functional/cProfileBox/CProfileBox'
import { CInformationItem } from '~/components/functional/information/CInformationItem'
import { CTutorialDialog, TutorialData } from '~/components/functional/tutorial/cTutorialDialog/CTutorialDialog'
import { DefaultLayout } from '~/components/layout/Default'
import { useConfirmationDialog } from '~/hooks/useConfirmationDialog'
import { useAuthState } from '~/store/auth'
import { mediaUrl, useQuerySuspense } from '~/utils/common'
import { createApiClient } from '~/utils/createApiClient'

export const useUserIndexPage = () => {
    const navigate = useNavigate()
    const apiClient = createApiClient()
    const { setMe, isTutorial, setIsTutorial } = useAuthState()
    const { queueDialog } = useConfirmationDialog()

    // initial fetch
    const { data: me } = useQuerySuspense(
        ['user/me'],
        async () => {
            const me = await apiClient.clientAuthUserMe()
            setMe(me)
            return me
        },
        {
            onError: async (e) => {
                let message = 'データ取得に失敗しました'
                if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
                await queueDialog({
                    type: 'alert',
                    title: 'エラーが発生しました',
                    text: message,
                })
            },
        },
    )
    const { data: address } = useQuerySuspense(
        ['/user/address'],
        async () => {
            const res = await apiClient.clientUserAddressGetMain({})
            return res.length > 0 ? res[0] : ({} as Schemas.UserAddressEntities)
        },
        {
            onError: async (e) => {
                let message = 'データ取得に失敗しました'
                if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
                await queueDialog({
                    type: 'alert',
                    title: 'エラーが発生しました',
                    text: message,
                })
            },
        },
    )
    const { data: responseNotificationList } = useQuerySuspense(
        ['/user/informationList'],
        async () => {
            return await apiClient.clientNotificationBoardIndex({ parameter: { limit: 3, page: 1 } })
        },
        {
            onError: async (e) => {
                let message = 'お知らせの取得に失敗しました'
                if (e instanceof AxiosError) message = e.response?.data.message || e.message || message
                await queueDialog({
                    type: 'alert',
                    title: 'エラーが発生しました',
                    text: message,
                })
            },
        },
    )
    const isAddProfile = () => {
        if (!address) return true
        if (!address?.sei) return true
        if (!address?.mei) return true
        if (!address?.postalCode) return true
        if (!address?.pref) return true
        if (!address?.city) return true
        if (!address?.address) return true
        return false
    }

    const copyHandler = async () => {
        navigator.clipboard.writeText(`${process.env.BASE_URL}/invite/${me!.invite}`)
        await queueDialog({
            type: 'alert',
            title: '招待URLをクリップボードにコピーしました',
            text: '',
        })
    }

    // tutorial
    const [isDisplayTutorial, setIsDisplayTutorial] = useState(isTutorial && tutorialData.length > 0)
    const { isOpen: tutorialDialogIsOpen, onOpen: tutorialDialogOnOpen, onClose: tutorialDialogOnClose } = useDisclosure()
    const openTutorialHandler = () => {
        tutorialDialogOnOpen()
    }
    const closeTutorialHandler = (value?: string) => {
        if (value) {
            setIsTutorial(false)
            setIsDisplayTutorial(false)
            navigate(value)
        }
        tutorialDialogOnClose()
    }
    useEffect(() => {
        if (isDisplayTutorial) tutorialDialogOnOpen()
    }, [])

    return {
        user: me,
        address,
        isAddProfile,
        informationList: responseNotificationList?.list || [],
        informationCount: responseNotificationList?.count || 0,
        navigate,
        copyHandler,

        tutorialDialogIsOpen,
        openTutorialHandler,
        closeTutorialHandler,
    }
}

export const UserIndexPage: FC = () => {
    const {
        user,
        address,
        isAddProfile,
        informationList,
        informationCount,
        navigate,
        copyHandler,

        tutorialDialogIsOpen,
        closeTutorialHandler,
    } = useUserIndexPage()

    return (
        <>
            <DefaultLayout maxWidth={'md'} breadcrumbList={[]} tabValue={'myPage'} isTab>
                <Container maxWidth={'sm'}>
                    <Stack direction={'row'} justifyContent={'end'} sx={{ pb: 2 }}>
                        <IconButton component={RouterLink} to="/help?source=user">
                            <img src={helpIcon} />
                        </IconButton>
                    </Stack>
                </Container>
                {user && (
                    <>
                        <Stack spacing={3}>
                            <Stack spacing={1}>
                                {isAddProfile() && (
                                    <Box bgcolor={'#FDEDEE'} p={1} width={'100%'}>
                                        <Button color={'error'} variant={'text'} onClick={() => navigate('/user/edit')}>
                                            <Stack direction={'row'} justifyContent={'start'} alignItems={'start'} spacing={1}>
                                                <ErrorIcon color={'error'} />
                                                <Typography
                                                    variant="body2"
                                                    color={'error'}
                                                    textAlign={'start'}
                                                    sx={{ textDecoration: 'underline', textUnderlineOffset: '4px' }}>
                                                    プロフィール情報の登録を完了させてください
                                                </Typography>
                                            </Stack>
                                        </Button>
                                    </Box>
                                )}
                                {!user.email && (
                                    <Box bgcolor={'#FDEDEE'} p={1} width={'100%'}>
                                        <Button color={'error'} variant={'text'} onClick={() => navigate('/user/mail')}>
                                            <Stack direction={'row'} justifyContent={'start'} alignItems={'start'} spacing={1}>
                                                <ErrorIcon color={'error'} />
                                                <Typography
                                                    variant="body2"
                                                    color={'error'}
                                                    textAlign={'start'}
                                                    sx={{ textDecoration: 'underline', textUnderlineOffset: '4px' }}>
                                                    メールアドレスの登録を完了させてください
                                                </Typography>
                                            </Stack>
                                        </Button>
                                    </Box>
                                )}
                            </Stack>
                            <Container>
                                <Stack spacing={3}>
                                    <Stack direction="column" justifyContent="flex-start" alignItems="center" spacing={2}>
                                        <Avatar
                                            alt={user.name}
                                            src={mediaUrl(user.file, true)}
                                            sx={{ width: 152, height: 152 }}
                                        />

                                        <Stack spacing={0.5}>
                                            <Typography variant="h3" textAlign={'center'}>
                                                {user.name}
                                            </Typography>
                                            <Typography variant="body2" textAlign={'center'}>
                                                {(address && address.sei) || ''}
                                                {(address && address.mei) || ''}
                                            </Typography>
                                        </Stack>
                                    </Stack>

                                    {/* お知らせ */}
                                    <Box sx={{ backgroundColor: 'white' }}>
                                        <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
                                            <img
                                                src={informationIcon}
                                                alt=""
                                                width={'52px'}
                                                style={{ position: 'relative', top: '-12px' }}
                                            />
                                            <Typography variant={'h6'}>お知らせ</Typography>
                                        </Stack>
                                        {informationCount === 0 && (
                                            <Typography sx={{ px: 2, py: 1 }} variant={'body2'}>
                                                お知らせはありません
                                            </Typography>
                                        )}
                                        {informationCount > 0 &&
                                            informationList.map((item: Schemas.NotificationBoardEntities) => (
                                                <CInformationItem key={item.uuid} item={item} />
                                            ))}
                                        {informationCount > 3 && (
                                            <Link
                                                color={'#77618B'}
                                                href={`/information`}
                                                variant={'body2'}
                                                sx={{ textAlign: 'center' }}>
                                                <Typography
                                                    sx={{ mx: 2, py: 1, borderTop: '1px solid #C6C6C8' }}
                                                    variant={'body1'}>
                                                    もっと見る
                                                </Typography>
                                            </Link>
                                        )}
                                    </Box>

                                    {/* アクション */}
                                    <Stack spacing={2}>
                                        <Stack>
                                            <Button
                                                variant={'contained'}
                                                fullWidth
                                                startIcon={<img src={iconEdit} />}
                                                onClick={() => navigate('/user/edit')}>
                                                マイプロフィールを編集
                                            </Button>
                                        </Stack>
                                        <Stack>
                                            <Button
                                                variant={'contained'}
                                                fullWidth
                                                startIcon={<img src={personalHistory} />}
                                                onClick={() => navigate('/user/history')}>
                                                自分史
                                            </Button>
                                        </Stack>
                                        <Stack direction={'row'} spacing={1}>
                                            <Button
                                                variant={'contained'}
                                                fullWidth
                                                startIcon={<img src={iconTomb} />}
                                                onClick={() => navigate('/grave')}>
                                                マイお墓
                                            </Button>
                                            <Button
                                                variant="contained"
                                                fullWidth
                                                startIcon={<img src={connectLink} alt="" />}
                                                onClick={() => copyHandler()}>
                                                招待URL
                                            </Button>
                                        </Stack>
                                    </Stack>

                                    <Stack spacing={1}>
                                        <CProfileBox
                                            label={'携帯電話'}
                                            value={[(user && user.mobilePhone) || '']}
                                            toPath={'/user/mobilePhone'}
                                        />
                                        <CProfileBox
                                            label={'メールアドレス'}
                                            value={[(user && user.email) || '']}
                                            toPath={'/user/mail'}
                                        />
                                        <CProfileBox
                                            label={'住所'}
                                            value={[
                                                `〒${(address && address.postalCode) || ''}`,
                                                `${(address && address.pref) || ''}${(address && address.city) || ''}${
                                                    (address && address.address) || ''
                                                }`,
                                                `${(address && address.building) || ''}`,
                                            ]}
                                        />
                                        <CProfileBox label={'電話番号'} value={[(address && address.tel) || '']} />
                                    </Stack>
                                    <Divider
                                        sx={{
                                            borderBottomColor: '#AF985A',
                                            borderBottomWidth: '1px',
                                            borderBottomStyle: 'solid',
                                        }}
                                    />
                                    <Stack>
                                        <Button
                                            variant={'outlined'}
                                            onClick={() => navigate('/connectList/new/edit', { state: { isDummy: true } })}>
                                            身内・友人のお墓を作成する
                                        </Button>
                                    </Stack>
                                </Stack>
                            </Container>
                        </Stack>
                    </>
                )}
            </DefaultLayout>
            <CTutorialDialog isOpen={tutorialDialogIsOpen} onClose={closeTutorialHandler} tutorialDataList={tutorialData} />
        </>
    )
}

export const tutorialData: TutorialData[] = []
