import { Box, Stack, Typography } from '@mui/material'
import type { FC, ReactNode } from 'react'

import hamburgerIcon from '~/assets/image/help/mypage/hamburgerIcon.png'
import mypageGrave from '~/assets/image/help/mypage/mypageGrave.png'
import mypageGraveMaintain from '~/assets/image/help/mypage/mypageGraveMaintain.png'
import mypageGraveMaintainThree from '~/assets/image/help/mypage/mypageGraveMaintainThree.png'
import mypageGraveMaintainTwo from '~/assets/image/help/mypage/mypageGraveMaintainTwo.png'
import mypageHistory from '~/assets/image/help/mypage/mypageHistory.png'
import mypageHistoryRegistrationForm from '~/assets/image/help/mypage/mypageHistoryRegistrationForm.png'
import mypageHistoryThree from '~/assets/image/help/mypage/mypageHistoryThree.png'
import mypageHistoryTwo from '~/assets/image/help/mypage/mypageHistoryTwo.png'
import myPageNotice from '~/assets/image/help/mypage/myPageNotice.png'
import mypageProfile from '~/assets/image/help/mypage/mypageProfile.png'
import mypageProfileTwo from '~/assets/image/help/mypage/mypageProfileTwo.png'
import mypageRelatives from '~/assets/image/help/mypage/mypageRelatives.png'
import mypageRelativesFour from '~/assets/image/help/mypage/mypageRelativesFour.png'
import mypageRelativesThree from '~/assets/image/help/mypage/mypageRelativesThree.png'
import mypageRelativesTwo from '~/assets/image/help/mypage/mypageRelativesTwo.png'
import mypageUrl from '~/assets/image/help/mypage/mypageUrl.png'
import { CHelpImage } from '~/components/functional/help/cHelpImage/CHelpImage'

import { CHelpCommonLink } from '../cHelpCommonLink/CHelpCommonLink'
import { CHelpCustomList } from '../cHelpCustomList/CHelpCustomList'
import { CHelpLayout } from '../cHelpLayout/CHelpLayout'

type UserSectionItems = {
    id: string
    title: string
    body?: ReactNode
    children?: ReactNode
}

export type CUserHelpProps = {
    onLinkClick: (sectionName: string, pathName: string, newTab: boolean) => void
}

export const useCUserHelp = (onLinkClick: (sectionName: string, pathName: string, newTab: boolean) => void) => {
    const orderedUrlListItems: (string | ReactNode)[] = [
        <>
            マイページ中央の<b>招待URL</b>ボタンを押すと、招待URLがコピーされます。
        </>,
        'これ終に招待したい人へ、メールや LINEなどのメッセージアプリでメッセージを作成',
        'コピーした招待URLをメッセージにペースト',
        'メッセージを送信',
    ]
    const unorderedPersonalHstoryItems: string[] = ['出来事の詳細文', '画像', 'YouTube動画のURL']
    const orderedGraveMaintainItems: ReactNode[] = [
        '相互のつながりのあるユーザーの中から、没後に管理して欲しい人のプロフィールページを開きます。',
        <>
            プロフィール編集画面から<b>没後管理権限</b>を<b>権限あり</b>に設定して登録します。
        </>,
    ]
    const userSectionItems: UserSectionItems[] = [
        {
            id: 'notice',
            title: 'お知らせ',
            children: (
                <>
                    <Typography variant={'body2'}>お知らせは以下のタイミングで通知されます。</Typography>
                    <CHelpCustomList
                        listStyleType={'disc'}
                        listItems={[
                            <>つながりの申請が来たとき</>,
                            <>つながり申請が承諾されたとき</>,
                            <>つながっている人の命日の30日前の事前通知、当日の通知</>,
                            <>管理中のお墓へお供え物がされたとき</>,
                        ]}
                    />
                    <CHelpImage src={myPageNotice} />
                    <Stack spacing={'0.625rem'}>
                        <Typography mt={'1rem'} variant={'h4'}>
                            つながり申請・承諾の確認
                        </Typography>
                        <Typography variant={'body2'}>
                            つながり機能（詳しくは
                            <CHelpCommonLink onClick={() => onLinkClick('', 'connectList', true)}>つながり</CHelpCommonLink>
                            をご覧ください）でつながりの申請があった場合、または相手への申請が承認された場合に通知が届きます。
                        </Typography>
                        <Typography variant={'body2'}>
                            この通知はつながり申請ページでも一覧で確認することができます。（詳しくは
                            <CHelpCommonLink onClick={() => onLinkClick('confirmConnections', 'connectList', true)}>
                                つながり申請の確認方法
                            </CHelpCommonLink>
                            をご覧ください）
                        </Typography>
                    </Stack>
                </>
            ),
        },
        {
            id: 'inviteURL',
            title: '招待URL',
            body: (
                <Typography variant={'body2'}>
                    招待URLを共有していただくと、これ終サービスを知人や身内・友人の方へおすすめしていただくことができます。
                </Typography>
            ),
            children: (
                <Stack>
                    <Typography fontSize={'1.125rem'} fontWeight={'700'} lineHeight={'150%'}>
                        招待URLの利用方法
                    </Typography>
                    <CHelpCustomList listStyleType={'disc'} listItems={orderedUrlListItems} />
                    <CHelpImage src={mypageUrl} />
                </Stack>
            ),
        },
        {
            id: 'profile',
            title: 'プロフィール',
            body: (
                <Typography variant={'body2'}>
                    ご自身のプロフィールを記入してください。
                    <br />
                    編集はマイページ中央の<b>マイプロフィールを編集</b>ボタンから行うことができます。
                    <br />
                    ※この情報は全体に公開されることはありません。
                </Typography>
            ),
            children: (
                <>
                    <CHelpImage src={mypageProfile} />
                    <Box pt={'1rem'}>
                        <Typography variant={'subtitle1'}>必ず入力していただきたい情報</Typography>
                    </Box>
                    <Stack spacing={'1rem'}>
                        <Stack>
                            <Typography variant={'subtitle1'}>住所</Typography>
                            {/* <Typography variant={'body2'}>
                                住所を登録することで、相手にギフトを贈ることができるようになります。
                            </Typography> */}
                        </Stack>
                        <Stack>
                            <Typography variant={'subtitle1'}>メールアドレス</Typography>
                            <Typography variant={'body2'}>
                                メールアドレスを登録することで、これ終への招待やつながりの利用ができるようになります。
                                <br />
                                登録方法：画面右上のハンバーガーメニュー
                                <img src={hamburgerIcon} alt={'hamburger'} />
                                を開き、アカウントメニュー内<b>メールアドレス登録</b>を選択してください。
                                <br />
                                ※プロフィール編集からは登録できません
                            </Typography>
                        </Stack>
                    </Stack>
                    <CHelpImage src={mypageProfileTwo} />
                </>
            ),
        },
        {
            id: 'personalHistory',
            title: '自分史',
            body: (
                <Typography variant={'body2'}>
                    自分史は、あなたの人生の年表です。親族や友人のために自分史を登録しましょう。
                </Typography>
            ),
            children: (
                <>
                    <Stack spacing={'0.625rem'}>
                        <Stack>
                            <Typography variant={'subtitle1'}>自分史の公開範囲</Typography>
                            <Typography variant={'body2'}>あなたの自分史は、公開されません。</Typography>
                        </Stack>
                        <Stack>
                            <Typography variant={'subtitle1'}>自分史の利用方法</Typography>
                            <Typography variant={'body2'}>
                                マイページ中央の<b>自分史</b>ボタンからご利用いただけます。
                            </Typography>
                        </Stack>

                        <Stack>
                            <Typography variant={'subtitle1'}>自分史の登録方法</Typography>
                            <CHelpCustomList
                                listStyleType={'decimal'}
                                fontSize={'18px'}
                                paddingBottom={'12px'}
                                listItems={[
                                    <>
                                        <b>自分史を登録</b>ボタンを選択します。
                                        <CHelpImage styles={{ marginLeft: '-1.5rem' }} src={mypageHistory} />
                                    </>,
                                    <>
                                        登録したい出来事があった年(西暦または和暦)・月、タイトルを入力してください。
                                        <br />
                                        自分史には、上記の他に
                                        <CHelpCustomList listStyleType={'disc'} listItems={unorderedPersonalHstoryItems} />
                                        を登録していただくことができます。
                                        <CHelpImage styles={{ marginLeft: '-1.5rem' }} src={mypageHistoryRegistrationForm} />
                                    </>,
                                ]}
                            />
                        </Stack>
                        <Stack>
                            <Typography variant={'subtitle1'}>自分史の編集・削除方法</Typography>
                            <CHelpCustomList
                                listStyleType={'decimal'}
                                fontSize={'18px'}
                                paddingBottom={'8px'}
                                listItems={[
                                    <>
                                        編集または削除したい自分史を選択します。
                                        <CHelpImage styles={{ marginLeft: '-1.5rem' }} src={mypageHistoryTwo} />
                                    </>,
                                    <>
                                        自分史詳細画面にある<b>編集</b>または<b>削除</b>
                                        ボタンより、お好みの操作をお願いいたします。
                                        <CHelpImage styles={{ marginLeft: '-1.5rem' }} src={mypageHistoryThree} />
                                    </>,
                                ]}
                            />
                        </Stack>
                    </Stack>
                </>
            ),
        },
        {
            id: 'grave',
            title: 'マイお墓',
            body: <Typography variant={'body2'}>マイお墓画面ではあなたの没後に公開されるお墓の設定ができます。</Typography>,
            children: (
                <Stack spacing={'0.625rem'}>
                    <Stack>
                        <Typography variant={'subtitle1'}>公開範囲</Typography>
                        <Typography variant={'body2'}>
                            つながりを承諾した人のみに表示されます。（つながりは
                            <CHelpCommonLink onClick={() => onLinkClick('connectRules', 'connectList', true)}>
                                つながり
                            </CHelpCommonLink>
                            ページから確認できます）
                        </Typography>
                    </Stack>
                    <Stack>
                        <Typography variant={'subtitle1'}>マイお墓の設定</Typography>
                        <Typography variant={'body2'}>墓石の種類・表示する名前を設定することができます。</Typography>
                        <CHelpImage src={mypageGrave} />
                    </Stack>
                </Stack>
            ),
        },
        {
            id: 'graveMaintain',
            title: 'お墓を管理する',
            body: (
                <>
                    <Typography variant={'body2'}>
                        あなた自身の没後に、これ終上であなたのお墓の管理を依頼するユーザーを設定できます。また、あなたが他のユーザーから管理を依頼されることもあります。
                        <br />
                        管理を依頼できるのは、相互のつながりのあるユーザーのみです。（つながりの確認方法は
                    </Typography>
                    <CHelpCommonLink onClick={() => onLinkClick('confirmConnections', 'connectList', true)}>
                        こちら
                    </CHelpCommonLink>
                    ）
                </>
            ),
            children: (
                <>
                    <Stack spacing={'0.625rem'} pt={'0.625rem'}>
                        <Typography variant={'subtitle1'}>依頼方法</Typography>
                        <CHelpCustomList listItems={orderedGraveMaintainItems} />
                        <CHelpImage src={mypageGraveMaintain} />
                    </Stack>
                    <Stack spacing={'0.625rem'} pt={'1rem'}>
                        <Typography variant={'subtitle1'}>依頼されたら</Typography>
                        <Typography variant={'body2'}>
                            管理依頼されると、あなたに管理依頼をしたユーザーのページに管理人タグが表示されます。
                            <br />
                            依頼者の死後、<b>プロフィールの編集</b>
                            から命日を登録することで、お墓の編集・お墓の写真のアップロードなどの管理ができるようになります。
                        </Typography>
                        <CHelpImage src={mypageGraveMaintainTwo} />
                    </Stack>
                    <Stack spacing={'0.625rem'} pt={'0.625rem'}>
                        <Typography variant={'subtitle1'}>管理中のお墓の確認方法</Typography>
                        <Typography variant={'body2'}>
                            画面右上のハンバーガーメニュー
                            <img src={hamburgerIcon} alt={'hamburger'} />
                            を開き、アカウントメニュー内<b>管理中のお墓</b>を選択してください。
                            <br />
                            ※プロフィール編集からは登録できません
                        </Typography>
                        <CHelpImage src={mypageGraveMaintainThree} />
                    </Stack>
                </>
            ),
        },
        {
            id: 'relativesGrave',
            title: '身内・友人の墓をつくる',
            body: '既に亡くなった方のお墓を作成できます。その方の人生や思い出を登録できます。',
            children: (
                <>
                    <Stack spacing={'0.625rem'}>
                        <Typography variant={'subtitle1'}>作成方法</Typography>
                        <Typography variant={'body2'}>
                            マイページ最下部の<b>身内・友人のお墓を作成する</b>
                            ボタン・または管理中のお墓ページから追加できます。
                        </Typography>
                        <CHelpImage src={mypageRelatives} />
                        <CHelpImage src={mypageRelativesFour} />
                    </Stack>
                    <Stack spacing={'0.625rem'} pt={'0.625rem'}>
                        <Typography variant={'subtitle1'}>身内・友人のお墓の編集</Typography>
                        <Typography variant={'body2'}>
                            マイお墓ボタンを選択し、次の画面で没年月日の設定、お墓の表示名の設定・お墓の写真のアップロードができます。
                        </Typography>
                        <Typography variant={'subtitle1'}>身内・友人のお墓の管理権限の譲渡</Typography>
                        <Typography variant={'body2'}>
                            あなたがなくなった場合、あなたが作成した身内・友人ユーザーとそのお墓の管理権限を、あなたのお墓の管理者に譲渡するか設定することができます。
                            この設定は、身内・友人のアカウント作成後、その身内・友人のプロフィール画面の<b>プロフィール編集</b>
                            ボタンからいつでも変更することができます。
                        </Typography>
                        <Box
                            display={'flex'}
                            gap={'0.625rem'}
                            flexDirection={{ xs: 'column', sm: 'row' }}
                            justifyContent={'center'}
                            alignItems={{ xs: 'center', sm: 'start' }}>
                            <CHelpImage src={mypageRelativesThree} />
                            <CHelpImage src={mypageRelativesTwo} />
                        </Box>
                        <Typography variant={'subtitle1'}>身内・友人のお墓の公開範囲</Typography>
                        <Typography variant={'body2'}>
                            相互でつながっている人のみに表示されます。（つながりは
                            <CHelpCommonLink onClick={() => onLinkClick('connectRules', 'connectList', true)}>
                                つながり
                            </CHelpCommonLink>
                            ページから確認できます）
                        </Typography>
                    </Stack>
                </>
            ),
        },
    ]
    return { userSectionItems, onLinkClick }
}

export const CUserHelp: FC<CUserHelpProps> = ({ onLinkClick }) => {
    const { userSectionItems } = useCUserHelp(onLinkClick)
    return (
        <Stack spacing={'2.5rem'} mt={'2.5rem'} data-testid="c-user-help">
            {userSectionItems.map((it, idx) => (
                <CHelpLayout key={idx} {...it} />
            ))}
        </Stack>
    )
}
